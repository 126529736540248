function displayImage($previewTemplate, name) {
	var extensionImages = ["jpg", "jpeg", "png"],
		extensionFile = name.split('.').pop(-1)
	;
	if (extensionImages.indexOf(extensionFile) !== -1) {
		var $img = $previewTemplate.find('img[data-dz-picture]');
		var src = Routing.generate('app_serve_document', { 'fileIdentifier' : name});
		$img.attr('src', src).show();
	}
}


function displayState(element, stateFolder, message, stateGlobalFolder) {
	var $uploadZoneContent = $(element).parent().parent();
	if (undefined !== stateFolder) {
		if (stateFolder === -1) {
			$(element).find('.state.text-warning').show();
		}
		if (stateFolder === 0) {
			$(element).find('.state.text-danger').show();

			if (stateGlobalFolder === 0) {
				$uploadZoneContent.find('.fuc-danger').show();
			}
		}
		if (stateFolder === 1) {
			$(element).find('.state.text-success').show();

			if (stateGlobalFolder === 1) {
				$uploadZoneContent.find('.fuc-success').show();
			}
		}
		if (message) {
			$uploadZoneContent.siblings('.folder-message').append(message).show();
		}
	} else {
		$(element).find('.state.text-warning').show();
	}
}

$(function () {
	$('[data-toggle="tooltip"]').tooltip();

	$('.accomodation-slider').owlCarousel({
		loop: 1,
		nav: 1,
		navText: ["<span class='icon icon-arrow-left7 large-icon'></span>", "<span class='icon icon-arrow-right7 large-icon'></span>"],
		dots: 0,
		items: 1,
		//autoplay: 1,
		smartSpeed: 1000,
		responsive: {
			0: {
				items: 1
			}
		}
	});


	Dropzone.autoDiscover = false;

	$('.uploader:not(.uploader-disabled)').each(function () {
		var unique = $(this).data('unique')
		initUploader($(this), unique);
	});

	/**
	 * Update icon to uploaded document for visa
	 *
	 * @param element
	 */
	function updateVisaDocumentIcons(element) {
		try {
			const uploadContainer = $(element)?.closest('.documents-upload-area');
			const uploadContainerId = uploadContainer?.attr('id');
			if (uploadContainerId) {
				const menu = $('#visa-documents-menu').find(`[data-target="#${uploadContainerId}"]`);
				const context = menu?.attr('data-context');
				const alias = menu?.attr('data-alias');
				if (context && alias) {
					//Get icon from backend
					$.ajax(Routing.generate('app_visa_document_icon', {context, alias}), {
						method: 'GET',
						success: function (res) {
							if (res && res.icon) {
								menu.find('img')?.attr('src', '/assets/images/' + res.icon);
							}
						}
					})
				}
			}
		} catch (e) {
			console.error(e);
		}
	}
	function initUploader(element, unique) {

		var docList = [];

		var context = element.data('context'), 
			alias = element.data('alias'), 
			id = element.data('extra-id'),
			entity = element.data('entity'),
			source = element.data('source');
		
		var fetchUrl = Routing.generate('app_upload_get_from_context', { 'context' : context, 'coma_aliases' : alias});
		var uploadurl = Routing.generate('app_upload', { 'context' : context, 'alias' : alias });
		var $clickableContent = element.find('.uploader-zone-content');
		$.ajax({
			method: 'GET',
			data: {extraId: id, entity},
			url: fetchUrl,
		}).done(function (res) {


			var $dropper = element.find('.dropper');

			var dropzoneInstance = $dropper.dropzone({
				acceptedFiles: ".jpg, .png, .doc, .docx, .pdf, .jpeg",
				dictDefaultMessage: "Choose or drop  file from your computer",
				url: uploadurl,
				clickable: $clickableContent[0],
				params: {
					extraId: id,
					entity,
					source,
				},
				// createImageThumbnails: false,
				uploadMultiple: false,
				maxFiles: unique ? 1 : 10,
				maxFilesize: 5,
				previewTemplate: element.find('.uploader-uploaded-item').html(),
				previewsContainer: element.find('.preview')[0],
				init: function () {
					this.on("addedfile", function() {
						while (this.files.length > this.options.maxFiles) {
							this.removeFile(this.files[0]);
						}
					});
				},
				success: function (file, res) {
					var $previewTemplate = $(file.previewTemplate);
					if (res.success) {
						updateVisaDocumentIcons(this.element);

						// on set l'id pour pouvoir faire la suppression plus tard
						file.id = res.document.id;
						// CA NE MARCHE PAS ...
						// on change le nom du file de dropzone avec celui généré par le serveur
						file.name = res.document.name;


						if ($previewTemplate.find('a[data-dl-link]')) {
							$previewTemplate.find('a[data-dl-link]').attr('href', res.document.url);
						}

						docList[res.document.id] = {id: res.document.id, name: res.document.name};

						if (unique) {
							$clickableContent.hide()
						}

						displayImage($previewTemplate, res.document.name);
						$previewTemplate.find('span[data-dz-errormessage]').hide();
						displayState($previewTemplate, -1);
					} else {
						displayState($previewTemplate, 0, '<br>server error: <br>' + res.error);
					}
				},
				removedfile: function (file) {

					//validated document cannot be deleted
					if (file.state === 1) {
						var currentLanguage = window.location.pathname.split('/')[1],
							message = 'Un document validé ne peut être supprimé';

						if (currentLanguage === 'en') {
							message = 'A validated document cannot be deleted';
						}

						toastr.error(message);
						
						return false;
					}

					if (file.id) {
						const element = this.element;
						var deleteUrl = Routing.generate('app_upload_remove', { 'id' : file.id });;
						$.ajax({
							method: 'DELETE',
							url: deleteUrl,
						}).done(function () {
							delete docList[file.id];
							updateVisaDocumentIcons(element);
						});
					}

					if (unique) {
						$clickableContent.show()
					}

					file.previewElement.remove();

				},

			})[0].dropzone;

			element.addClass('dropzone') // pour css

			//Ajout de la progress bar
			dropzoneInstance.on("totaluploadprogress", function(progress) {
				element.find('.upload-in-progress').show();
				element.find('.form-upload-container').hide();
				if (progress === 100) {
					setTimeout(() => {
						element.find('.upload-in-progress').hide();
						element.find('.form-upload-container').show();
					}, 2000);
				}
			});

			dropzoneInstance.on('complete', function() {
				element.find('.upload-in-progress').hide();
				element.find('.form-upload-container').show();
			});

			// populate avec les doc déja uploadés :
			if (res.filesUploaded.length) {
				if (unique) {
					$clickableContent.hide()
				}


				$.each(res.filesUploaded, function (key, doc) {


					var file = {
							name: doc.name,
							size: doc.size,
							status: Dropzone.ADDED,
							accepted: true,
							id: doc.id,
							stateFolder: doc.stateFolder,
							isParent: doc.isParent, 
							state: doc.state
						},
						$previewTemplate = $(file.previewTemplate);

					dropzoneInstance.emit("addedfile", file);

					$previewTemplate = $(file.previewTemplate);

					if ($previewTemplate.find('a[data-dl-link]')) {
						$previewTemplate.find('a[data-dl-link]').attr('href', doc.url);
					}

					displayImage($previewTemplate, doc.name);

					dropzoneInstance.emit("complete", file);

					displayState(file.previewTemplate, doc.state, doc.isParent ? doc.message : '', doc.stateFolder);

					docList[doc.id] = doc;
				});
			}
		});
	}

	//when burger menu is display : scroll top
	$(document).on('shown.bs.collapse', '.header-collapse', function () {
		var $menu = $(this);
		$menu.get(0).scrollIntoView({
			block: 'start',
			behavior: 'smooth'
		});
	})

	// $('#btn-qa').click(function (){
	// 	getListQa();
	// });


	$(window).on('resize', function () {
		resizeIframe();
	});

	$(window).on('load', function () {
		resizeIframe();
	});


});

const getListQa = () => {
	alert('ici');
}

const resizeIframe = () => {
	$('.inline-editing-tinymce').find('img, iframe').each(function () {
		const iframe = $(this);
		const iframeContainer = iframe.closest('.inline-editing-tinymce');
		let iframeContainerWidth = iframeContainer.width();
		const iframeWidth = iframe.width();
		const iframeHeight = iframe.height();

		//the iframe is inside tab
		if (iframeContainerWidth === 0){
			const tabItemContainer = iframe.closest('.tab-item');
			iframeContainerWidth = tabItemContainer.width();

			if (iframe[0].tagName === 'IMG') {
				return;
			}		
		}

		const aspectRatio = iframeHeight / iframeWidth ;
	
		if (iframeContainerWidth <= iframeWidth) {
			iframe.css({
				width: iframeContainerWidth,
				height: (iframeContainerWidth * aspectRatio)+ 'px'
			});
		} else {
			iframe.css({
				width: '',
				height: ''
			});
		};
	});
}